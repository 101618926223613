import User from 'lib@/user';
import {log} from 'lib@/util';
import LayoutCpn from 'cpn@/layout_cpn';


/**
 * 全局基础操作
 */
class App
{
    constructor() {
        this._req         = null;
        this._res         = null;
        this._next        = null;
        this._router      = null;
        this._shownLayout = false;
    }

    get req() {
        return this._req;
    }

    get res() {
        return this._res;
    }

    get next() {
        return this._next;
    }

    get router() {
        return this._router;
    }

    set req(req) {
        this._req = req;
    }

    set res(res) {
        this._res = res;
    }

    set next(next) {
        this._next = next;
    }

    set router(router) {
        this._router = router;
    }

    /**
     * 获取模版渲染函数的方法
     * @param {string} tplName
     * @returns promise
     */
    async getTpl(tplName) {
        try{
            let {default: tpl} = await import(`tpl@/${tplName}.art`);
            return tpl;
        }
        catch(error){
            log(error);
        }
        return () => {};
    }

    /**
     * 载入页面对象的方法
     * @param {string} pageName 
     * @returns promise
     */
    async getPage(pageName) {
        try{
            log(pageName);
            pageName = pageName.replace(/\-/g, '_');
            if(pageName.charAt(0) == '/'){
                pageName = pageName.substr(1);
            }
            let {default: page} = await import(`page@/${pageName}.js`);
            return page;
        }
        catch(error){
            log(error);
            this.router.redirect('/404');
        }
    }

    /**
     * 输出默认布局界面的方法
     * @returns Promise
     */
    outputLayout(){
        return new Promise((resolve, reject) => {
            if(this._shownLayout){
                resolve();
            }
            else{
               
                new LayoutCpn({
                    App: this
                });
                this._shownLayout = true;
                resolve();
            }
        });
    }

    /**
     * 在默认布局的主区域内输出 html 的方法
     * @param {string} html 
     */
    render(html){
        $('#main_body').html(html);
    }

    /**
     * 在整个页面内输出 html 的方法
     * @param {string} html 
     */
    renderFully(html){
        this.res.render(html);
        this._shownLayout = false;
    }

    /**
     * 附加分页参数的方法
     * @param {number} page 
     * @param {number} perPage 
     * @param {object} other 
     * @returns object
     */
    getReqPaginator( other = null,page = 1, perPage = 20){
        return {
            page: page,
            per_page: perPage,
            ...other,
        };
    }

    /**
     * 获取 符合分页返回结构的 数据实体
     * @param {object} resData 
     * @returns object
     */
    getResPaginator(resData){
        return {
            total       : resData.total || 0,
            per_page    : resData.per_page || 20,
            current_page: resData.current_page || 1,
            last_page   : resData.last_page || 1,
            data        : resData.data || [],
        };
    }
}

export default new App();